<template>
    <div>
        <div>
            <div class="list-group-item info-block-contact" v-for="countLabel in Object.keys(relations)" :key="countLabel">
                <div>
                    <span>{{ $t(`relations.${countLabel}`) }}: {{relations[countLabel]}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RelationsBlock',
    props: ['relations'],
    data() {
        return {
            // Add your component data here
        };
    },
    methods: {
        // Add your component methods here
    },
    computed: {
        // Add your computed properties here
    }
};
</script>

<style scoped>
</style>